.main-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

button {
    margin-left: 5px;
}

.table-actions {
    width: 150px;
}

.menu-analise-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 5px;
    gap: 5px;
    padding: 0px;
}

.menu-analise-item,
.menu-analise-item-active,
.menu-analise-item:hover {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    width: 400px;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;
    list-style: none;
    padding-inline: 20px;
    cursor: pointer;
    font-size: clamp(0.5rem, 0.9rem, 0.9rem);
    text-align: center;
}
.menu-analise-item {
    background-color: #ba0000;
}

.menu-analise-item-active {
    background-color: #930000;
}

.menu-analise-item:hover {
    background-color: #cb0b0b;
}

.botao-excluir:hover {
    cursor: pointer;
    background-color: #ef2323;
    color: #ffffff;
}

.botao-excluir {
    cursor: default;
    background-color: #fe3232;
    color: #ffffff;
}


/* COR BOTÃO BOOTSTRAP PERSONALIZADA*/

/* Fundo preenchido */
.btn-brown {
    background-color: #8b4513 !important;
    color: white !important;
    border-color: #8b4513 !important;
}

.btn-brown:hover {
    background-color: #5a2e0d !important;
    border-color: #5a2e0d !important;
}

/* Estilo outline */
.btn-outline-brown {
    background-color: transparent !important;
    color: #8b4513 !important;
    border: 1px solid #8b4513 !important;
}

.btn-outline-brown:hover {
    background-color: #8b4513 !important;
    color: white !important;
}

/* Estilização da imagem e animação */
.alerta-triangulo > img {
    width: 30px; /* Largura inicial da imagem */
    height: 30px; /* Manter proporção */
    -webkit-animation: aumentarDiminuir 0.5s infinite;
            animation: aumentarDiminuir 0.5s infinite; /* Nome da animação, duração e repetição */
    cursor: pointer;
    will-change: transform;
}

.alerta-triangulo-visualizado > img {
    width: 22px; /* Largura inicial da imagem */
    height: 22px; /* Manter proporção */
}

/* Definição da animação */
@-webkit-keyframes aumentarDiminuir {
    0%,
    100% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9); /* Tamanho normal */
    }
    50% {
        -webkit-transform: scale(0.75);
                transform: scale(0.75); /* Encolhe para 80% do tamanho */
    }
}
@keyframes aumentarDiminuir {
    0%,
    100% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9); /* Tamanho normal */
    }
    50% {
        -webkit-transform: scale(0.75);
                transform: scale(0.75); /* Encolhe para 80% do tamanho */
    }
}

.displayPDF {
    position: relative;
    padding-top: 80px;
    overflow: hidden;
}
.displayPDF input,
.displayPDF button {
    font: inherit;
}

.displayPDF header {
    position: fixed;
    top: 47px; /* required */
    z-index: 2;
    display: -webkit-flex;
    display: flex;
    background-color: #323639;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0;
    margin: 0;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    cursor: default;
}

.displayPDF header .closePDF,
.displayPDF header .buttonsPDF {
    margin: 0;
    padding: 20px;
    font-size: 1.3rem;
}

.displayPDF header .buttonsPDF {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    min-width: 300px;
}
.displayPDF header div i {
    cursor: pointer;
}
.displayPDF__container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    overflow: hidden;
    max-width: 100vw;
}

.displayPDF__container__document::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.displayPDF__container__document {
    overflow: auto; /* Permite rolagem */
    max-width: 100%; /* Limita a largura máxima */
}

.displayPDF__container__document .react-pdf__Document {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: start;
            align-items: start;
}

.displayPDF__container__document .react-pdf__Page {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.displayPDF__container__document .react-pdf__Page canvas {
    /* max-width: 80vw; */
    height: auto !important;
}

@media print {
    /* @page {
    size: auto;
  } */
    html,
    body {
        margin: 0;
        padding: 0;
    }

    .displayPDF__container__document {
        width: 99.7% !important;
        padding: 0 !important;
        margin: 0 !important;
        page-break-before: avoid;
        page-break-after: avoid;
        overflow: hidden;
    }
    .displayPDF__container__document .react-pdf__Document,
    .displayPDF__container__document .react-pdf__Page,
    .displayPDF__container__document .react-pdf__Page canvas {
        width: 100% !important;
        max-width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
        page-break-before: avoid;
        page-break-after: avoid;
        overflow: hidden;
    }
}

.textLayer {
    position: absolute;
    top: 0;
    left: 0;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1;
    pointer-events: auto !important;
    z-index: 2;
}


.textLayer span {
    position: absolute;
    white-space: pre;
}

.linha-cores-intercaladas > td:nth-child(even){
  background-color:  var(--backgroundColorTd); 
}

.linha-cores-intercaladas > th:nth-child(odd){
  background-color: var(--backgroundColorTr);
}

.linha-cores-intercaladas > th:nth-child(even){
  background-color: var(--backgroundColorTd);
}

.linha-cores-intercaladas td{
   border-top: 1px solid #777;
}

.linha-cores-intercaladas:nth-last-child(2) td{
   border-bottom: 1px solid #777;
}

.linha-cores-intercaladas:nth-last-child(1) td{
   border-bottom: 1px solid #777;
}

.linha-cores-intercaladas td:nth-child(1){
  border-left: 1px solid #777;
}

.linha-cores-intercaladas td:nth-last-child(1){
  border-right: 1px solid #777;
}

.linha-cores-intercaladas:hover td{
  border-top:1px solid #333;
  border-bottom: 1px solid #333;
}

.linha-cores-intercaladas:hover td:nth-child(1){
  border-left: 1px solid #333;
}

.linha-cores-intercaladas:hover td:nth-last-child(1){
  border-right: 1px solid #333;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.modal-content {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.119);
	width: 90%;
	max-width: 1000px;
}

.close-button {
	background-color: #ff2040;
	color: #ffffff;
	border: none;
	border-radius: 3px;
	width: 30px;
	height: 30px;
}

.file-chooser{
    margin-block:20px;
}

.file-chooser-input{
    display: -webkit-flex;
    display: flex;
}
.file-chooser-input span{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    display: grid;
    -webkit-align-items: center;
            align-items: center; 
    padding-inline: 10px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
}
.file-chooser-input button{
    min-width: -webkit-fit-content;
    min-width: fit-content;
    outline: none;
    background-color: transparent;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    border: 1px solid #007bff;
    color:#007bff;
    padding: 6px 30px;
}

 .file-chooser-input .remover{
    color: #dc3545;
    border:1px solid #dc3545

 }

  .file-chooser-input .vazio{
   color: #898989;
   cursor: pointer;

 }
.map-container {
  width: 100%;
  height: 100%;
}

.geracao-leitura-status-alerta-amarela {
  border: #fff solid 1px;
  cursor: pointer;
  -webkit-animation: animate-amarela 0.8s linear infinite;
          animation: animate-amarela 0.8s linear infinite;
}

@-webkit-keyframes animate-amarela {
  0% {
    box-shadow: #d4a509 0px 0px 15px 5px;
  }
  50% {
    box-shadow: #d4a509 0 0 0;
  }
  100% {
    box-shadow: #d4a509 0px 0px 15px 5px;
  }
}

@keyframes animate-amarela {
  0% {
    box-shadow: #d4a509 0px 0px 15px 5px;
  }
  50% {
    box-shadow: #d4a509 0 0 0;
  }
  100% {
    box-shadow: #d4a509 0px 0px 15px 5px;
  }
}

.geracao-leitura-status-alerta-vermelha {
  border: #fff solid 1px;
  cursor: pointer;
  -webkit-animation: animate-vermelha 0.8s linear infinite;
          animation: animate-vermelha 0.8s linear infinite;
}

@-webkit-keyframes animate-vermelha {
  0% {
    box-shadow: #f00 0px 0px 15px 5px;
  }
  50% {
    box-shadow: #f00 0 0 0;
  }
  100% {
    box-shadow: #f00 0px 0px 15px 5px;
  }
}

@keyframes animate-vermelha {
  0% {
    box-shadow: #f00 0px 0px 15px 5px;
  }
  50% {
    box-shadow: #f00 0 0 0;
  }
  100% {
    box-shadow: #f00 0px 0px 15px 5px;
  }
}

.semi-circle-progress-container {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  
  .semi-circle-progress-container svg {
    -webkit-transform: rotate(-200deg);
            transform: rotate(-200deg); /* Ajuste inicial */
  }

  .semi-circle-progress-container svg circle {
    fill: none;
    stroke-width: 7;
  }
    
  .semi-circle-progress-container .progress-text {
    width: 100%;
    position: absolute;
    padding-top: 18px;
    font-size: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
  }

  .semi-circle-progress-container .progress-text:hover {
    color:#fff !important
}; 
  
.wrapper {
	background-color: #fff !important;
}

.login-box-body {
	background-color: #eee;
}

.login-box button {
	margin-left: 0px;
}

.login-box a:hover {
	cursor: pointer;
}

.animacao-form {
	width: 100%;
	height: 100%;
	-webkit-animation: animacao 0.4s;
	        animation: animacao 0.4s;
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
}

@-webkit-keyframes animacao {
	from {
		opacity: 0%;
	}
	to {
		opacity: 100%;
	}
}

@keyframes animacao {
	from {
		opacity: 0%;
	}
	to {
		opacity: 100%;
	}
}

.install-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    z-index: 1000;
    display: -webkit-flex;
    display: flex;
    grid-gap:10px;
    gap:10px;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.install-banner p {
    margin: 0;
    padding: 0;
    font-size: 16px;
}

.install-banner button.btn-install-ok {
    background-color: #f62222;
    color: white;
    padding: 8px 12px;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 4px;
    width: 200px;
}

.install-banner button.btn-install-cancel {
    background-color: #5a5a5a;
    color: white;
    padding: 8px 12px;
    border: none;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 4px;
    width: 200px;
}

.install-banner button:hover {
    background-color: #c92323;
}
