.textLayer {
    position: absolute;
    top: 0;
    left: 0;
    font-family: sans-serif;
    font-size: 1rem;
    line-height: 1;
    pointer-events: auto !important;
    z-index: 2;
}


.textLayer span {
    position: absolute;
    white-space: pre;
}
