.main-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
}

button {
    margin-left: 5px;
}

.table-actions {
    width: 150px;
}

.menu-analise-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0px;
}

.menu-analise-item,
.menu-analise-item-active,
.menu-analise-item:hover {
    flex-grow: 1;
    width: 400px;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;
    list-style: none;
    padding-inline: 20px;
    cursor: pointer;
    font-size: clamp(0.5rem, 0.9rem, 0.9rem);
    text-align: center;
}
.menu-analise-item {
    background-color: #ba0000;
}

.menu-analise-item-active {
    background-color: #930000;
}

.menu-analise-item:hover {
    background-color: #cb0b0b;
}

.botao-excluir:hover {
    cursor: pointer;
    background-color: #ef2323;
    color: #ffffff;
}

.botao-excluir {
    cursor: default;
    background-color: #fe3232;
    color: #ffffff;
}


/* COR BOTÃO BOOTSTRAP PERSONALIZADA*/

/* Fundo preenchido */
.btn-brown {
    background-color: #8b4513 !important;
    color: white !important;
    border-color: #8b4513 !important;
}

.btn-brown:hover {
    background-color: #5a2e0d !important;
    border-color: #5a2e0d !important;
}

/* Estilo outline */
.btn-outline-brown {
    background-color: transparent !important;
    color: #8b4513 !important;
    border: 1px solid #8b4513 !important;
}

.btn-outline-brown:hover {
    background-color: #8b4513 !important;
    color: white !important;
}

/* Estilização da imagem e animação */
.alerta-triangulo > img {
    width: 30px; /* Largura inicial da imagem */
    height: 30px; /* Manter proporção */
    animation: aumentarDiminuir 0.5s infinite; /* Nome da animação, duração e repetição */
    cursor: pointer;
    will-change: transform;
}

.alerta-triangulo-visualizado > img {
    width: 22px; /* Largura inicial da imagem */
    height: 22px; /* Manter proporção */
}

/* Definição da animação */
@keyframes aumentarDiminuir {
    0%,
    100% {
        transform: scale(0.9); /* Tamanho normal */
    }
    50% {
        transform: scale(0.75); /* Encolhe para 80% do tamanho */
    }
}
